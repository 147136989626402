import React from 'react'
import { Link } from "gatsby";

const NotFoundPage = () => (
  <div style={{ fontSize: 30, textAlign: 'center', marginTop: 50 }}>
    <div>Seite nicht gefunden :(</div>
    <Link to={'/'}>else-ury-campus.de</Link>
  </div>
)

export default NotFoundPage
